@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root,
.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #b78135;
  color: black;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}

.ui{
  position: absolute;
  height: 10vh;
  width: 100vw;
  background-color:transparent ;
  z-index: 10;
  margin: 10px;

  .copyright{
    margin:0px;
    font-weight: bold
  }
}

